.menuTitle {
    color: red;
}

.websites {
    display: inline-flex;
    background-color:#4c4c53;
}

img {
    width:270px;
    height: auto;
}

#red_potion {
    width: 300px;
}

@media only screen and (max-width: 600px) {
    .websites {
        flex-direction: column;
    }

    #red_potion {
        width: 100px;
        height: 100px;
    }

    #green_potion {
        width: 90px;
        height: 90px;
    }

    #purple_potion {
        width: 120px;
        height: 120px;
    }

    #lilac_potion {
        width: 120px;
        height: 120px;
    }
}