.navbar {
    width: 105%;
    height: 100px;
    background-color: #282845;
    display: flex;
    flex-direction: row;
}

.navbar .leftSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left:150px;
}

.navbar h1 {
  font-size:80px;
}
.navbar .leftSide img {
    width: 80px;
}

.navbar .rightSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
}

.navbar .rightSide button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    width: 100px;
}

.navbar .rightSide svg {
    font-size: 40px;
  }

  .navbar #open img {
    display: none;
  }
  
  .navbar #close img {
    display: inherit;
  }
  
  .navbar #open .hiddenLinks {
    display: inherit;
    margin-left: 30px;
  }
  
  .navbar #close .hiddenLinks {
    display: none;
  }
  
  .navbar #open a {
    width: 70px;
    margin: -5px;
  }
  @media only screen and (max-width: 900px) {
    .navbar .rightSide a {
      width: 70px;
    }
    .navbar .leftSide {
      padding-left: 50px;
    }
  }

  @media only screen and (max-width: 2028px) {
    .navbar h1 {
      font-size:30px;
    }
  }

  @media only screen and (max-width: 775px) {
    .navbar h1 {
      font-size:15px;
      display: flex;

    }
  }
  
  @media only screen and (max-width: 600px) {
    .navbar .rightSide a {
      display: none;
    }

    .navbar #open .hiddenLinks {
      display: flex;
      flex-direction: column;
      margin-left: 0;
    }
  
    .navbar #open a {
      width: auto;
      margin: 1px 0;
    }
  
    .navbar .rightSide {
      justify-content: flex-end;
      padding-right: 50px;
    }
  
    .navbar .rightSide button {
      display: inherit;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .navbar .rightSide button {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }
  }