.contact {
    width: 100%;
    height: 70vh;
    display: flex;
}

.contact .leftSide {
    height: 100%;
    flex: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contact .rightSide {
    height: 100%;
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

p { 
    white-space: normal;
    font-family: 'Courier New', Courier, monospace;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 29px;
    }
    
    P {
        font-size: 20px;
    }
  }