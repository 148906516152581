body {
  margin: 0;
  font-family: "Pixel Script";
  background-color: black;
  text-align:center;
}


@media (max-width: 768px) {
  p {
    width: auto;
  }
}

@media only screen and (max-width: 600px) {
  body {
    width: fit-content;
    font-size: 14px; 
    line-height: 1.4; 
  }
}

.stars {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.stars:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 3px;
  background-color: white;
  box-shadow: 
  100px 200px white, 
  200px 50px yellow, 
  300px 150px white, 
  400px 20px yellow, 
  500px 180px white, 
  600px 100px yellow, 
  700px 250px white, 
  800px 70px yellow, 
  900px 200px white, 
  1000px 150px yellow,
  150px 300px white,
  250px 450px yellow,
  350px 100px white,
  450px 250px yellow,
  550px 400px white,
  650px 50px yellow,
  750px 200px white,
  850px 350px yellow,
  950px 500px white,
  50px 400px yellow,
  150px 50px white,
  250px 200px yellow,
  350px 350px white,
  450px 500px yellow,
  550px 100px white,
  650px 250px yellow,
  750px 400px white,
  850px 50px yellow,
  950px 200px white,
  100px 350px yellow,
  1100px 300px white,
  1200px 50px yellow,
  1300px 200px white,
  1400px 350px yellow,
  1500px 500px white,
  1600px 100px yellow,
  1700px 250px white,
  1800px 400px yellow,
  1900px 50px white,
  2000px 200px yellow,
  2100px 300px white,
  2200px 450px yellow,
  2250px 475px white,
  2280px 480px yellow,
  2300px 100px white;

  animation: twinkling 10s linear infinite;
}

@keyframes twinkling {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}