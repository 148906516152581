#title {
    color:#305f92;
}

.statement_head {
    color:#305f92;
}

.statement {
    color: #e1e8ef;
    margin: 0 auto;
    width: 660px;
    text-align: center;
    font-size: 26px;
}

@media only screen and (max-width: 600px) {
    
    .statement_head {
        font-size: 19px;
    }
    
    .statement {
        font-size: 16px;
        line-height: 1.4;
        width: auto;
    }
}
