.footer {
        width: 100%;
        background-color: #121619;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        position: fixed;
        bottom: 0;      
  }

.footer .languageButton button {
  background-color: transparent;
  border-style: none;
  color: rgb(137, 185, 226);
}

  @media (max-width: 768px) {

    .footer {
      padding-top: 0px;
    }
  
    .footer .socialMedia svg {
      font-size: 40px;
    }
  }
  
  .socialMedia svg {
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    color: white;
  }

  @media only screen and (max-width: 420px) {
    .footer {
        position: static;
    }}